<template>
  <div class="main">
    <div class="condition-container">
      <div>
        <div class="condition-title">设备序列号</div>
        <input class="condition-input" type="text" v-model="ruleForm.id" :disabled="loading"/>
      </div>
      <div>
        <div class="condition-title">环境</div>
        <select class="condition-input" v-model="ruleForm.env" :disabled="loading">
          <option v-for="(countries,env) in envMap" :key="env" :value="env">{{ env }}</option>
        </select>
      </div>
      <div>
        <div class="condition-title">国家</div>
        <select class="condition-input" v-model="ruleForm.country" :disabled="loading">
          <option v-for="(countries,env) in countryMap" :key="env" :value="env">{{ env }}</option>
        </select>
      </div>
      <div style="margin: 20px 0 10px 100px;">
        <a class="query-btn" @click="query" :disabled="loading" href="javascript:void(0)">查询</a>
        <a class="query-btn" @click="reset" :disabled="loading" href="javascript:void(0)">重置</a>
      </div>
    </div>
    <hr/>
    <div v-if="false">
      <pre>{{ JSON.stringify(queryResult, null, 2) }}</pre>
    </div>
    <div class="result-container" v-if="queryResult">
      <div>
        <div class="result-title">设备机身序列号(userSn)</div>
        <div class="result-value">{{ queryResult.userSn }}</div>
      </div>
      <div>
        <div class="result-title">设备ID(serialNumber)</div>
        <div class="result-value">{{ queryResult.serialNumber }}</div>
      </div>
      <div>
        <div class="result-title">源型号</div>
        <div class="result-value">{{ queryResult.originalModelNo }}</div>
      </div>
      <div>
        <div class="result-title">生产型号</div>
        <div class="result-value">{{ queryResult.modelNo }}</div>
      </div>
      <div>
        <div class="result-title">客户型号</div>
        <div class="result-value">{{ queryResult.displayModelNo || '' }}</div>
      </div>
      <div>
        <div class="result-title">当前绑定设备的账号ID</div>
        <div class="result-value">{{ queryResult.userId || '' }}</div>
      </div>
      <div>
        <div class="result-title">当前绑定设备的账号所属TenantId</div>
        <div class="result-value">{{ queryResult.tenantId || '' }}</div>
      </div>
      <div>
        <div class="result-title">当前绑定设备的账号所在国家</div>
        <div class="result-value">{{ queryResult.countryName || '' }}({{ queryResult.countryNo || '' }})</div>
      </div>
      <div>
        <div class="result-title">最近一次上报的信号强度(RSSI)</div>
        <div class="result-value">{{ queryResult.signalStrength || '未知' }}</div>
      </div>
      <div>
        <div class="result-title">设备是否带屏蔽罩</div>
        <div class="result-value">{{ showBoolValue(queryResult.isShield) || '未知' }}</div>
      </div>
      <div>
        <div class="result-title">设备是否外置天线</div>
        <div class="result-value">{{ isExternalAntenna() }}</div>
      </div>
      <div>
        <div class="result-title">设备是否支持设置WI-FI发射功率档位</div>
        <div class="result-value">{{ showBoolValue(queryResult.supportWifiPowerLevel) || '未知' }}</div>
      </div>
      <div>
        <div class="result-title">最近一次上报的WI-FI发射功率档位</div>
        <div class="result-value">{{ queryResult.wifiPowerLevel || '未知' }}</div>
        <a class="query-btn" v-if="queryResult.supportWifiPowerLevel" :disabled="loading"
           @click="openEditor" href="javascript:void(0)">修改</a>
        <!--        <a class="query-btn" @click="openEditor" :disabled="loading" href="javascript:void(0)">修改</a>-->
      </div>
      <div v-show="showEditor">
        <div class="result-title">修改WI-FI发射功率档位</div>
        <select class="condition-input" v-model="inputWifiPowerlevel" :disabled="loading">
          <option v-for="(val) in wifiPowerlevelOptions" :key="val" :value="val">{{ val }}</option>
        </select>
        <a class="query-btn" @click="closeEditor" :disabled="loading" href="javascript:void(0)">取消</a>
        <a class="query-btn" @click="update" :disabled="loading" href="javascript:void(0)">确定</a>
      </div>
    </div>
    <!--
      <div v-if="queryResult" v-show="showEditor" class="editor">
        <div>修改WI-FI发射功率档位</div>
        <div class="condition-title">SN:{{ queryResult.userSn }}</div>
        <select class="condition-input" v-model="inputWifiPowerlevel" :disabled="loading">
          <option v-for="(val) in wifiPowerlevelOptions" :key="val" :value="val">{{ val }}</option>
        </select>
        <div>
          <a class="query-btn" @click="update" :disabled="loading" href="javascript:void(0)">确定</a>
          <a class="query-btn" @click="closeEditor" :disabled="loading" href="javascript:void(0)">取消</a>
        </div>
      </div>
      -->
  </div>
</template>

<script>
import axios from 'axios';

const queryResultTemplate = {
  "serialNumber": "ddab6b8341458650d384b4c43362f7ff",
  "displayModelNo": "X82-W",
  "signalStrength": -56,
  "isShield": false,
  "wifiPowerLevel": null,
  "countryNo": "CN",
  "modelNo": "CG621-W-JS",
  "userSn": "AICTGN1FV2F2698",
  "userId": 950,
  "supportWifiPowerLevel": false,
  "tenantId": "guard",
  "originalModelNo": "CG621-W",
  "countryName": "中国",
  "isExternalAntenna": false
};

function createEnvMap() {
  return {
    'test': {
      'cn': 'https:api-test.addx.live'
    },
    'staging': {
      'cn': 'https://api-stage.addx.live',
      'eu': 'https://api-staging-eu.addx.live',
      'us': 'https://api-staging-us.addx.live',
    },
    'pre': {
      'cn': 'https://api-pre.addx.live',
      'eu': 'https://api-pre-eu.addx.live',
      'us': 'https://api-pre-us.addx.live',
    },
    'prod': {
      'cn': 'https://api.addx.live',
      'eu': 'https://api-eu.addx.live',
      'us': 'https://api-us.addx.live',
    },
  }
};

export default {
  components: {},
  data() {
    return {
      ruleForm: {
        id: "ddab6b8341458650d384b4c43362f7ff",
        env: "staging",
        country: "cn",
      },
      rules: {
        id: {
          required: true,
          message: "请输入设备序列号",
          trigger: "blur"
        },
        country: {
          required: true
        },
        env: {
          required: true,
        }
      },
      envMap: createEnvMap(),
      queryResult: null,

      wifiPowerlevelOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      inputWifiPowerlevel: 0,
      showEditor: false,
      loading: false,
    };
  },
  created() {
    this.reset();
  },
  computed: {
    countryMap() {
      return this.envMap[this.ruleForm.env];
    },
    baseUrl() {
      return this.countryMap[this.ruleForm.country];
    }
  },
  watch: {
    countryMap(countryMap) {
      // 默认国家不存在，选第一个
      if (countryMap[this.ruleForm.country]) {
        for (const country in this.countryMap) {
          this.ruleForm.country = country;
          break;
        }
      }
    }
  },
  methods: {
    queryWifiPowerLevel(userSnOrSn) {
      this.loading = true;
      const requestConfig = {
        url: '/postSale/queryWifiPowerLevel',
        method: 'post',
        data: {userSnOrSn},
        baseURL: this.baseUrl
      };
      axios.request(requestConfig)
          .then(res => {
            if (res.data.result !== 0) {
              alert(res.data.msg);
              this.queryResult = null;
            } else {
              console.log('queryResult:', res.data);
              this.queryResult = res.data.data;
              this.inputWifiPowerlevel = this.queryResult.wifiPowerLevel || 0;
            }
            this.loading = false;
          })
          .catch(res => {
            this.queryResult = null;
            this.loading = false;
            alert("查询失败！");
          });
    },
    updateWifiPowerLevel(sn, wifiPowerLevel) {
      this.loading = true;
      const requestConfig = {
        url: '/postSale/updateWifiPowerLevel',
        method: 'post',
        data: {sn, wifiPowerLevel},
        baseURL: this.baseUrl
      };
      axios.request(requestConfig)
          .then(res => {
            if (res.data.result !== 0) {
              alert(res.data.msg);
            } else {
              alert("更新成功！");
            }
            this.loading = false;
            this.queryWifiPowerLevel(sn);
          })
          .catch(res => {
            this.loading = false;
            alert("更新失败！");
            this.queryWifiPowerLevel(sn);
          });
    },
    query() {
      if (this.ruleForm.id && this.ruleForm.id.trim() !== '') {
        this.queryWifiPowerLevel(this.ruleForm.id);
      } else {
        alert("设备序列号不能为空!");
      }
    },
    openEditor() {
      this.showEditor = true;
    },
    closeEditor() {
      this.showEditor = false;
      this.inputWifiPowerlevel = this.queryResult.wifiPowerLevel || 0;
    },
    update() {
      if (!this.wifiPowerlevelOptions.find(v => v === this.inputWifiPowerlevel)) {
        alert("WI-FI功率档位取值错误！");
      } else {
        this.updateWifiPowerLevel(this.queryResult.serialNumber, this.inputWifiPowerlevel);
      }
      this.closeEditor();
    },
    reset() {
      this.ruleForm.id = "";
      this.ruleForm.env = "prod";
      this.ruleForm.country = "us";
      this.queryResult = null;
      this.inputWifiPowerlevel = null;
      this.showEditor = false;
      this.loading = false;
    },
    showBoolValue(val) {
      if (val === true) return '是';
      else if (val === false) return '否';
      else return null;
    },
    isExternalAntenna() {
      const result = this.showBoolValue(this.queryResult.isExternalAntenna);
      if (result) return result;
      /* 在后端返回isExternalAntenna字段为null的情况下根据型号判断：
      需要初始化这些源型号下的所有衍生型号天线类型是外置天线：CC721, CG721-A,CG721C ,CQ121B, CQ121C, CQ121C1，
      以及除衍生型号CG522-JS以外的CG522下的其他衍生型号都是外置天线
       */
      const includeOriginalModelNos = ['CC721', 'CG721-A', 'CG721C', 'CQ121B', 'CQ121C', 'CQ121C1', 'CG522'];
      const excludeModelNos = ['CG522-JS'];
      const flag1 = includeOriginalModelNos.includes(this.queryResult.originalModelNo);
      const flag2 = excludeModelNos.includes(this.queryResult.modelNo);
      console.log('includeOriginalModelNos:', flag1, 'excludeModelNos:', flag2);
      return this.showBoolValue(flag1 && !flag2);
    }
  }
  ,
  mounted() {
  }
  ,
}
;
</script>

<style>
.main {
  width: 100%;
  height: auto;
  margin: 15px;
  padding: 0;
  background: white;
}

.condition-container {
  width: 100%;
  padding: 40px 0 10px 40px;
}

.result-container {
  width: 100%;
  padding: 10px 0 40px 40px;
}

.condition-title, .condition-input, .result-title, .result-value {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  height: 30px;
}

.query-btn {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 30px;
  height: 30px;
  color: rgb(22, 93, 255);
  margin-left: 50px;
}

.condition-input {
  width: 320px;
}

.result-title {
  display: inline-block;
  width: 320px;
}

.result-value {
  display: inline-block;
  margin-left: 20px;
}

/*.editor {*/
/*  position: absolute;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/
</style>
